import {graphql} from 'gatsby'
import IndexPage from '../index-page'

export default IndexPage

export const pageQuery = graphql`
  query IndexPageEnTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        image
        category
        lang
      }
    }
    allCategoriesYaml(filter: {data: {elemMatch: {lang: {eq: "en"}}}}) {
      edges {
        node {
          data {
            category
            name
            icon
          }
        }
      }
    }
    categoryPages: allMarkdownRemark(
      sort: {fields: [frontmatter___index], order: ASC},
      filter: {frontmatter: {templateKey: { eq: "article-page" }, invisible:{ne: true}, lang:{eq: "en"}}}) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              index
              title
            }
          }
        }
      }
    }
  }    
`